@import '../../assets/styles/styles.scss';

%coming-soon {
    color: $mai-gray-shade;
    opacity: 0.3;
    font-size: 0.75rem;
    margin-left: 0.313rem;
}

.navbarwrapper {
    width: 100%;
    color: $white;
    height: 100%;
    min-height: 100vh;
    display: flex !important;
    align-items: flex-start !important;
    font-size: 1.313rem;
    padding: 0;

    .navbar__listdesc {
        width: 100%;
        height: 100%;
        display: flex;
    }

    .navbar__menulist {
        border-right: 0.063rem solid $mai-light-purple-shade;
        padding: 1.25rem 0.5rem;
        width: 3.75rem;
        background-color: $mai-dark-purple-shade;

        .navbar__ul {
            list-style: none;
            margin: 0;
            padding: 0;

            .navbar__list {
                margin-bottom: 0.938rem;
                text-align: center;
                padding: 0;

                &[aria-expanded="true"] {
                    .menu__switchImg {
                        transform: rotate(90deg) !important;
                        transition: all 0.5s;
                    }
                }

                &[aria-expanded="false"] {
                    .menu__switchImg {
                        transform: rotate(0deg) !important;
                        transition: all 0.5s;
                    }
                }

                &.active {
                    background-color: $mai-light-purple-shade;
                    position: relative;

                    &::before {
                        position: absolute;
                        left: -7px;
                        top: 50%;
                        content: "";
                        transform: translateY(-50%);
                        width: 4px;
                        height: 23px;
                        background: #BF3145;
                        border-radius: 6px;
                    }

                    .navbar__anchor {
                        .navbar__img {
                            opacity: 1;
                        }
                    }
                }

                &.navbar__list--active {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: "";
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -0.438rem;
                        background-color: $mai-rosy-brown;
                        width: 90%;
                        height: 0.063rem;
                        border-radius: 0.313rem;
                    }
                }

                &:first-child {
                    margin-bottom: 1.875rem;
                    text-align: center;

                    .navbar__anchor {
                        width: auto;
                        height: auto;
                        padding: 0;
                    }
                }

                .navbar__anchor {
                    width: 95%;
                    height: 2.75rem;
                    display: inline-block;
                    text-align: center;
                    border-radius: 0.5rem;
                    padding: 0.188rem 0;

                    &:focus-visible {
                        outline: none;
                    }

                    &.navbar--active {
                        background-color: $mai-light-purple-shade;

                        .navbar__img {
                            opacity: 1;
                        }
                    }

                    .navbar__img {
                        opacity: 0.5;
                    }

                }
            }
        }
    }

    .navbar__logosection {
        width: 14.375rem;
        background-color: $mai-dark-purple-shade;

        // &.collapse-horizontal {
        //     transition: all ease 0s;
        // }

        .navbar__logo {
            margin: 1.563rem 1rem ;
            text-align: center;
        }

        .navbar__tab-ul {
            margin: 1.25rem 0 0;
            padding: 0;
            list-style: none;

            .navbar__tab-ullist {
                margin-bottom: 0.313rem;
                background: transparent;
                border: none;


                .navbar__tab-anchor {
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.375rem;
                    text-decoration: none;
                    display: block;
                    padding: 0.5rem 0.938rem;
                    color: $mai-rosy-brown;
                    background-color: transparent;
                    box-shadow: none;
                    position: relative;


                    &::after {
                        background-image: url(../../assets/images/arrow-down-white.svg);
                        position: absolute;
                        right: 0.625rem;
                        top: 0.938rem;
                        background-size: 100%;
                        transform: rotate(-90deg);
                        transition: all ease 0.5s;
                        width: 0.625rem;
                        height: 0.625rem;
                    }

                    &:focus {
                        box-shadow: none;
                    }

                    &.navbar__tab--active {
                        background-color: $mai-light-purple-shade;
                        color: $mai-rosy-brown;
                    }

                    .navbar__comingsoon {
                        @extend %coming-soon;
                    }
                }

                .navbar__tab-anchor:not(.collapsed)::after {
                    top: 0.938rem;
                    transform: rotate(0);
                }
            }
        }


    }
}

.navbar__submenu {
    margin: 0;
    padding: 0;

    .navbar__submenu-list {

        a {
            font-size: 0.875rem;
            color: $white;
            font-weight: 400;
            padding: 0.5rem 0.625rem;
            text-decoration: none;
            display: block;
            line-height: 1.25rem;

            &.active {
                background: $mai-light-purple-shade;
                border-radius: 0.5rem 0 0 0.5rem;
            }

            // &.submenu--active {
            //     background: $mai-light-purple-shade;
            //     border-radius: 0.5rem 0 0 0.5rem;
            // }

            img {
                margin-right: 1rem;
                width: 0.875rem;
            }
            

            &.navbar__coming-soon {
                @extend %coming-soon;
            }
        }
    }
    .navbar__submenu-list.disabled-list {
        pointer-events: none; 
        opacity: 0.5; 
        cursor: not-allowed;
    }
}

.fade:not(.show) {
    display: none;
}